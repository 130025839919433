var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "terms-page" },
    [_c("code-of-ethics", { attrs: { showBtn: false, showCloseBtn: false } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }